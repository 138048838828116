import { User } from "@src/utils/user";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  user: User;
};

const ClubProfileContainer = ({ user }: Props) => {
  const { t } = useTranslation();
  const InfoField: React.FC<{
    icon: string;
    label: string;
    value?: string | number;
  }> = ({ icon, label, value }) => (
    <div className="grid grid-cols-[20px,1fr] items-center">
      <img src={icon} className="size-[15px]" />
      <div className="font-semibold">{label}:</div>
      <div />
      <div>{value || "—"}</div>
    </div>
  );
  const fields = [
    {
      icon: "/images/icons/user.svg",
      label: t("main.profileViewTabs.editProfile.form.clubname"),
      value: user.club?.name,
    },
    {
      icon: "/images/icons/user.svg",
      label: t("main.profileViewTabs.editProfile.form.legalForm"),
      value: user.club?.legal_type,
    },
    {
      icon: "/images/icons/user.svg",
      label: t("main.profileViewTabs.editProfile.form.clubID"),
      value: user.club?.club_register_nr,
    },
    {
      icon: "/images/icons/user.svg",
      label: t("main.profileViewTabs.editProfile.form.vatID"),
      value: user.club?.tax_nr,
    },
    {
      icon: "/images/icons/user.svg",
      label: t("main.profileViewTabs.editProfile.form.annualsales"),
      value: user.club?.yearly_revenue,
    },
    {
      icon: "/images/icons/user.svg",
      label: t("components.shared.amountEmployees"),
      value: user.club?.members,
    },
    {
      icon: "/images/icons/user.svg",
      label: t("components.shared.amountMembers"),
      value: user.club?.members,
    },
    {
      icon: "/images/icons/user.svg",
      label: t("main.profileViewTabs.editProfile.form.topics"),
      value: user.club?.specialization,
    },
    {
      icon: "/images/icons/user.svg",
      label: t("main.groupsInfoForm.keywords"),
      value: user.club?.keywords,
    },
    {
      icon: "/images/icons/user.svg",
      label: t("main.profileViewTabs.editProfile.form.moreInformation.imprint"),
      value: user.club?.legal_notice,
    },
    {
      icon: "/images/icons/user.svg",
      label: t("main.profileViewTabs.editProfile.form.moreInformation.termsCondition"),
      value: user.club?.terms,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      {fields.map(field => (
        <>
          <InfoField icon={field.icon} label={field.label} value={field.value} />
        </>
      ))}
    </div>
  );
};
export default ClubProfileContainer;

import BackButton from "@src/components/nav/BackButton";
import { useTranslation } from "react-i18next";

const InfoContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="grid grid-flow-row gap-4 text-justify text-xs">
        <div className="grid grid-flow-row gap-4 text-justify text-xs max-xl:px-4">
          <div className="text-left text-smbase font-bold">{t("pages.infoContainer.text1")}</div>
          <p>{t("pages.infoContainer.text2")}</p>
          <p>
            {t("pages.infoContainer.text3")}
            <b className="font-semibold"> {t("pages.infoContainer.text4")} </b>
            {t("pages.infoContainer.text5")}
          </p>
          <p>{t("pages.infoContainer.text6")}</p>
          <p>{t("pages.infoContainer.text7")}</p>
          <p>{t("pages.infoContainer.text8")}</p>
          <p className="font-bold">{t("pages.infoContainer.text9")}</p>
          <p>{t("pages.infoContainer.text10")}</p>
          <p>{t("pages.infoContainer.text11")}</p>
          <p>{t("pages.infoContainer.text12")}</p>
        </div>
        <p className="grid grid-cols-3 gap-1">
          <img src="/images/info/tf-cover-1.jpg" className="w-full object-contain" />
          <img src="/images/info/tf-cover-2.jpg" className="w-full object-contain" />
          <img src="/images/info/tf-cover-3.jpg" className="w-full object-contain" />
        </p>
        <div className="grid grid-flow-row gap-4 text-justify text-xs max-xl:px-4">
          <p className="font-bold">{t("pages.infoContainer.text13")}</p>
          <p>{t("pages.infoContainer.text14")}</p>
          <p>{t("pages.infoContainer.text15")}</p>
          <p>{t("pages.infoContainer.text16")}</p>
          <p>{t("pages.infoContainer.text17")}</p>
          <p>{t("pages.infoContainer.text18")}</p>
          <p className="font-bold">{t("pages.infoContainer.text19")}</p>
        </div>
        <p className="flex justify-center">
          <img src="/images/info/tf-create-account.jpg" className="h-[200px] object-contain" />
        </p>
        <div className="grid grid-flow-row gap-4 text-justify text-xs max-xl:px-4">
          <p>{t("pages.infoContainer.text20")}</p>
          <p>{t("pages.infoContainer.text21")}</p>
        </div>
        <p className="flex justify-center">
          <img src="/images/info/tf-charts.jpg" className="h-[200px] object-contain" />
        </p>
        <div className="grid grid-flow-row gap-4 text-justify text-xs max-xl:px-4">
          <p>{t("pages.infoContainer.text22")}</p>
          <p>{t("pages.infoContainer.text23")}</p>
        </div>
        <p className="flex justify-center">
          <img src="/images/info/tf-action.jpg" className="h-[200px] object-contain" />
        </p>
        <div className="grid grid-flow-row gap-4 text-justify text-xs max-xl:px-4">
          <p>{t("pages.infoContainer.text24")}</p>
          <p>{t("pages.infoContainer.text25")}</p>
        </div>
        <p className="flex justify-center">
          <img src="/images/info/tf-mail.jpg" className="h-[200px] object-contain" />
        </p>
        <div className="grid grid-flow-row gap-4 text-justify text-xs max-xl:px-4">
          <p>{t("pages.infoContainer.text26")}</p>
          <p>{t("pages.infoContainer.text27")}</p>
        </div>
        <p className="flex justify-center">
          <img src="/images/info/tf-action.jpg" className="h-[200px] object-contain" />
        </p>
        <div className="grid grid-flow-row gap-4 text-justify text-xs max-xl:px-4">
          <p>{t("pages.infoContainer.text28")}</p>
          <p>{t("pages.infoContainer.text29")}</p>
        </div>
        <p className="flex justify-center">
          <img src="/images/info/tf-products.jpg" className="h-[200px] object-contain" />
        </p>
        <div className="grid grid-flow-row gap-4 pb-4 text-justify text-xs max-xl:px-4">
          <p>{t("pages.infoContainer.text30")}</p>
          <p>{t("pages.infoContainer.text31")}</p>
          <p>{t("pages.infoContainer.text32")}</p>
          <p className="font-bold">{t("pages.infoContainer.text33")}</p>
        </div>
      </div>
    </>
  );
};

export default InfoContainer;

import isApp from "@src/utils/isApp";

const AppLink = () => {
  if (isApp) return <></>;

  return (
    <div className="mt-2 flex flex-row justify-center gap-2">
      <a
        href="https://apps.apple.com/de/app/tradefoox/id6451158918"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/images/button_apple.svg" alt="Apple" className="h-8" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=io.ionic.tradefoox&hl=de&gl=DE"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/images/button_chrome.svg" alt="Chrome" className="h-8" />
      </a>
    </div>
  );
};

export default AppLink;

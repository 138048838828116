import BackButton from "@src/components/nav/BackButton";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const PrivacyContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <BackButton stepback desktopVisible />
      <div className="flex flex-col gap-3 pb-4 text-justify text-xs text-gray-700">
        <h1 className="text-left text-smbase font-bold">
          {t("pages.moreContainer.privacyPolicy")}
        </h1>
        <div>{t("pages.privacyContainer.text1")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text2")}</h2>
        <div>{t("pages.privacyContainer.text3")}</div>
        <div>
          {t("pages.privacyContainer.text4")}{" "}
          <NavLink to="/cookies" className="text-darkcyan">
            {t("pages.cookiesContainer.header")}
          </NavLink>
          , {t("pages.privacyContainer.text5")}{" "}
          <NavLink to="/help" className="text-darkcyan">
            {t("pages.privacyContainer.text6")}
          </NavLink>{" "}
          {t("pages.privacyContainer.text7")}
        </div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text8")}</h2>
        <ul className="list-inside list-decimal">
          <li>{t("pages.privacyContainer.text9")}</li>
          <li>{t("pages.privacyContainer.text10")}</li>
          <li>{t("pages.privacyContainer.text11")}</li>
          <li>{t("pages.privacyContainer.text12")}</li>
          <li>{t("pages.privacyContainer.text13")}</li>
        </ul>
        <h2 className="font-semibold">1. {t("pages.privacyContainer.text14")}</h2>
        <div>{t("pages.privacyContainer.text15")}</div>
        <div>{t("pages.privacyContainer.text16")}</div>

        <h2 className="font-semibold">{t("pages.privacyContainer.text17")}</h2>
        <div>{t("pages.privacyContainer.text18")}</div>
        <div>{t("pages.privacyContainer.text19")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text20")} </h2>
        <div>{t("pages.privacyContainer.text21")}</div>
        <div>{t("pages.privacyContainer.text22")}</div>
        <div>{t("pages.privacyContainer.text23")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text24")}</h2>
        <div>{t("pages.privacyContainer.text25")}</div>
        <div>{t("pages.privacyContainer.text26")}</div>
        <div>{t("pages.privacyContainer.text27")}</div>
        <h2 className="font-semibold">2. 1. {t("pages.privacyContainer.text28")}</h2>
        <h2 className="font-semibold">1.1 {t("pages.privacyContainer.text29")}</h2>
        <div>{t("pages.privacyContainer.text30")}</div>
        <h2 className="font-semibold">{t("main.header.links.register")}</h2>
        <div>{t("pages.privacyContainer.text31")}</div>
        <div>{t("pages.privacyContainer.text32")}</div>
        <h2 className="font-semibold">{t("main.shoppingVenture.profile")}</h2>
        <div>{t("pages.privacyContainer.text33")}</div>
        <div>{t("pages.privacyContainer.text34")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text35")}</h2>
        <div>{t("pages.privacyContainer.text36")}</div>
        <div>{t("pages.privacyContainer.text37")}</div>
        <div>{t("pages.privacyContainer.text38")}</div>
        <h2 className="font-semibold">1.2 {t("pages.privacyContainer.text39")}</h2>
        <div>{t("pages.privacyContainer.text40")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text41")}</h2>
        <div>{t("pages.privacyContainer.text42")}</div>
        <div>{t("pages.privacyContainer.text43")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text44")}</h2>
        <div>{t("pages.privacyContainer.text45")}</div>
        <div>{t("pages.privacyContainer.text46")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text47")}</h2>
        <div>{t("pages.privacyContainer.text48")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text49")}</h2>
        <div>{t("pages.privacyContainer.text50")}</div>
        <h2 className="font-semibold">1.3 {t("pages.privacyContainer.text51")}</h2>
        <div>{t("pages.privacyContainer.text52")}</div>
        <div>{t("pages.privacyContainer.text53")}</div>
        <h2 className="font-semibold">1.4 {t("pages.privacyContainer.text54")}</h2>
        <div>{t("pages.privacyContainer.text55")}</div>
        <div>{t("pages.privacyContainer.text56")}</div>
        <h2 className="font-semibold">1.5 {t("pages.privacyContainer.text57")}</h2>
        <div>{t("pages.privacyContainer.text206")}</div>
        <div>{t("pages.privacyContainer.text58")}</div>
        <h2 className="font-semibold">1.6 {t("pages.privacyContainer.text59")}</h2>
        <div>{t("pages.privacyContainer.text60")}</div>
        <div>
          {t("pages.privacyContainer.text61")}{" "}
          <NavLink to="/nutzungsbedingungen" className="text-darkcyan">
            {t("pages.privacyContainer.text62.1")}
          </NavLink>{" "}
          {t("pages.privacyContainer.text62.2")}{" "}
          <NavLink to="/community" className="text-darkcyan">
            {t("pages.privacyContainer.text63")}
          </NavLink>{" "}
          {t("pages.privacyContainer.text64")}
        </div>
        <h2 className="font-semibold">1.7 {t("pages.privacyContainer.text65")}</h2>
        <div>{t("pages.privacyContainer.text66")}</div>
        <div>{t("pages.privacyContainer.text67")}</div>
        <h2 className="font-semibold">1.8 {t("pages.privacyContainer.text68")}</h2>
        <div>{t("pages.privacyContainer.text69")}</div>
        <div>{t("pages.privacyContainer.text70")}</div>
        <h2 className="font-semibold">1.{t("pages.privacyContainer.text71")}</h2>
        <div>{t("pages.privacyContainer.text72")}</div>
        <div>{t("pages.privacyContainer.text73")}</div>
        <h2 className="font-semibold">3. 2. {t("pages.privacyContainer.text74")}</h2>
        <div>{t("pages.privacyContainer.text75")}</div>
        <div>{t("pages.privacyContainer.text76")}</div>
        <h2 className="font-semibold">2.1 {t("pages.privacyContainer.text77")}</h2>
        <div>{t("pages.privacyContainer.text78")}</div>
        <div>{t("pages.privacyContainer.text79")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text80")}</h2>
        <div>{t("pages.privacyContainer.text81")}</div>
        <div>{t("pages.privacyContainer.text82")}</div>
        <div>{t("pages.privacyContainer.text83")}</div>
        <div>{t("pages.privacyContainer.text84")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text85")}</h2>
        <div>{t("pages.privacyContainer.text86")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text87")}</h2>
        <div>{t("pages.privacyContainer.text88")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text89")}</h2>
        <div>{t("pages.privacyContainer.text90")}</div>
        <h2 className="font-semibold">2.2 {t("pages.privacyContainer.text91")}</h2>
        <div>{t("pages.privacyContainer.text92")}</div>
        <div>{t("pages.privacyContainer.text93")}</div>
        <h2 className="font-semibold">2.3 {t("pages.privacyContainer.text94")}</h2>
        <div>{t("pages.privacyContainer.text95")}</div>
        <div>{t("pages.privacyContainer.text96")}</div>
        <div>{t("pages.privacyContainer.text97")}</div>
        <h2 className="font-semibold">2.4 {t("pages.privacyContainer.text98")}</h2>
        <div>{t("pages.privacyContainer.text99")}</div>
        <div>{t("pages.privacyContainer.text100")}:</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text101")}</li>
          <li>{t("pages.privacyContainer.text102")}</li>
          <li>{t("pages.privacyContainer.text103")}</li>
          <li>{t("pages.privacyContainer.text104")}</li>
          <li>{t("pages.privacyContainer.text105")}</li>
        </ul>
        <div>{t("pages.privacyContainer.text106")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text107")}</h2>
        <div>{t("pages.privacyContainer.text108")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text109")}</h2>
        <div>{t("pages.privacyContainer.text110")}</div>
        <h2 className="font-semibold">2.5 {t("pages.privacyContainer.text111")}</h2>
        <div>{t("pages.privacyContainer.text112")}</div>
        <div>{t("pages.privacyContainer.text113")}</div>
        <h2 className="font-semibold">2.6 {t("pages.privacyContainer.text114")}</h2>
        <div>{t("pages.privacyContainer.text115")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text116")}</h2>
        <div>{t("pages.privacyContainer.text117")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text118")}</h2>
        <div>{t("pages.privacyContainer.text119")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text120")}</h2>
        <div>{t("pages.privacyContainer.text121")}</div>
        <h2 className="font-semibold">2.7 {t("pages.privacyContainer.text122")}</h2>
        <div>{t("pages.privacyContainer.text123")}</div>
        <div>{t("pages.privacyContainer.text124")}</div>
        <h2 className="font-semibold">2.8 {t("pages.privacyContainer.text125")}</h2>
        <div>{t("pages.privacyContainer.text126")}</div>
        <div>{t("pages.privacyContainer.text127")}</div>
        <h2 className="font-semibold">2.9 {t("pages.privacyContainer.text128")}</h2>
        <div>{t("pages.privacyContainer.text129")}</div>
        <div>{t("pages.privacyContainer.text130")}</div>
        <h2 className="font-semibold">4. 3. {t("pages.privacyContainer.text132")}</h2>
        <h2 className="font-semibold">3.1 {t("pages.privacyContainer.text133")}</h2>
        <div>{t("pages.privacyContainer.text134.1")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text134.2")}</h2>
        <div>{t("pages.privacyContainer.text135")}</div>
        <div>{t("pages.privacyContainer.text136")}</div>
        <div>{t("pages.privacyContainer.text137")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text138")}</li>
          <li>{t("pages.privacyContainer.text139")}</li>
          <li>{t("pages.privacyContainer.text140")}</li>
          <li>{t("pages.privacyContainer.text141")}</li>
          <li>{t("pages.privacyContainer.text142")}</li>
          <li>{t("pages.privacyContainer.text143")}</li>
          <li>{t("pages.privacyContainer.text144")}</li>
        </ul>
        <div>{t("pages.privacyContainer.text145")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text146")}</h2>
        <div>{t("pages.privacyContainer.text147")}</div>
        <div>{t("pages.privacyContainer.text148")}</div>
        <div>{t("pages.privacyContainer.text149")}</div>
        <h2 className="font-semibold">3.2 {t("pages.privacyContainer.text150")}</h2>
        <div>{t("pages.privacyContainer.text151")}</div>
        <div>{t("pages.privacyContainer.text152")}</div>
        <h2 className="font-semibold">3.3 {t("pages.privacyContainer.text153")}</h2>
        <div>{t("pages.privacyContainer.text154")}</div>
        <div>{t("pages.privacyContainer.text155")}</div>
        <div>{t("pages.privacyContainer.text156")}</div>
        <h2 className="font-semibold">3.4 {t("pages.privacyContainer.text157")}</h2>
        <div>{t("pages.privacyContainer.text158")}</div>
        <div>{t("pages.privacyContainer.text159")}</div>
        <h2 className="font-semibold">3.5 {t("pages.privacyContainer.text160")}</h2>
        <div>{t("pages.privacyContainer.text161")}</div>
        <div>{t("pages.privacyContainer.text162")}</div>
        <h2 className="font-semibold">3.6 {t("pages.privacyContainer.text163")}</h2>
        <div>{t("pages.privacyContainer.text164")}</div>
        <div>{t("pages.privacyContainer.text165")}</div>
        <h2 className="font-semibold">3.7 {t("pages.privacyContainer.text166")}</h2>
        <div>{t("pages.privacyContainer.text167")}</div>
        <div>{t("pages.privacyContainer.text168")}</div>
        <h2 className="font-semibold">5. 4. {t("pages.privacyContainer.text169")}</h2>
        <h2 className="font-semibold">4.1 {t("pages.privacyContainer.text170")}</h2>
        <div>{t("pages.privacyContainer.text171")}</div>
        <div>{t("pages.privacyContainer.text172")}</div>
        <h2 className="font-semibold">4.2 {t("pages.privacyContainer.text173")}</h2>
        <div>{t("pages.privacyContainer.text174")}</div>
        <div>{t("pages.privacyContainer.text175")}</div>
        <div>{t("pages.privacyContainer.text176")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text177")}</li>
          <li>{t("pages.privacyContainer.text178")}</li>
          <li>{t("pages.privacyContainer.text179")}</li>
          <li>{t("pages.privacyContainer.text180")}</li>
        </ul>
        <div>{t("pages.privacyContainer.text181")}</div>
        <div>{t("pages.privacyContainer.text182")}</div>
        <h2 className="font-semibold">4.3 {t("pages.privacyContainer.text183")}</h2>
        <div>{t("pages.privacyContainer.text184")}</div>
        <div>{t("pages.privacyContainer.text185")}</div>
        <div>{t("pages.privacyContainer.text186")}</div>
        <div>{t("pages.privacyContainer.text187")}</div>
        <h2 className="font-semibold">6. 5. {t("pages.privacyContainer.text188")}</h2>
        <h2 className="font-semibold">5.1. {t("pages.privacyContainer.text189")}</h2>
        <div>{t("pages.privacyContainer.text190")}</div>
        <div>{t("pages.privacyContainer.text191")}</div>
        <h2 className="font-semibold">5.2. {t("pages.privacyContainer.text192")}</h2>
        <div>{t("pages.privacyContainer.text193")}</div>
        <div>{t("pages.privacyContainer.text194")}</div>
        <h2 className="font-semibold">5.3. {t("pages.privacyContainer.text195")}</h2>
        <div>{t("pages.privacyContainer.text196")}</div>
        <div>{t("pages.privacyContainer.text197")}</div>
        <div>{t("pages.privacyContainer.text198")}</div>
        <h2 className="font-semibold">5.4. {t("pages.privacyContainer.text199")}</h2>
        <div>{t("pages.privacyContainer.text200")}</div>
        <div>{t("pages.privacyContainer.text201")}</div>
        <h2 className="font-semibold">5.5. {t("pages.privacyContainer.text202")}</h2>
        <div>{t("pages.privacyContainer.text203")}</div>
        <div>{t("pages.privacyContainer.text204")}</div>
        <div>
          <div className="text-xs font-bold">
            {t("main.profileViewTabs.aboutMe.content.furtherInfo")}
          </div>
          <div className="flex flex-col gap-0.5 text-xs">
            <NavLink to="/datenschutz" className="text-darkcyan">
              {t("pages.conditionContainer.text14")}
            </NavLink>
            <NavLink to="/urheberrecht" className="text-darkcyan">
              {t("pages.conditionContainer.text15")}
            </NavLink>
            <NavLink to="/cookies" className="text-darkcyan">
              {t("pages.conditionContainer.text16")}
            </NavLink>
            <NavLink to="/community" className="text-darkcyan">
              {t("pages.comRulesContainer.text1")}
            </NavLink>
            <NavLink to="/nutzungsbedingungen" className="text-darkcyan">
              {t("pages.conditionContainer.text17")}
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyContainer;

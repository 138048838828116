import BackButton from "@src/components/nav/BackButton";
import { useTranslation } from "react-i18next";

const WatchFAQ = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="max-xl:pl-4 max-xl:pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="grid grid-flow-row gap-6 pb-4 text-justify text-xs text-gray-700">
        <section className="relative">
          <img
            src="/images/info/projekt-auftragsvergabe-header-5.jpg"
            className="h-[300px] w-full object-cover"
          />
          <div className="absolute left-6 top-1/2 flex -translate-y-1/2 flex-col font-semibold italic">
            <div className="w-fit bg-white/80 px-1 text-2xl text-darkcyan">
              {t("pages.watch.text1")}
            </div>
            <div className="ml-4 w-fit bg-darkblue/80 px-1 text-white">
              {t("pages.companyFAQ.tradefoox")}
            </div>
          </div>
        </section>

        <article className="max-xl:px-4">
          <h1 className="mb-4 text-left text-smbase font-bold">{t("pages.companyFAQ.text1")}</h1>
          <div className="grid grid-flow-row gap-3">
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">{t("pages.watch.text2")}</h2>
              {t("pages.watch.text3")}
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">{t("pages.watch.text4")}</h2>
              {t("pages.watch.text5.1")}
              <br />
              {t("pages.watch.text5.2")}
              <br />
              {t("pages.watch.text5.3")}
              <br />
              {t("pages.watch.text5.4")}
              <br />
              {t("pages.watch.text5.5")}
              <br />
              {t("pages.watch.text5.6")}
              <br />
            </div>
            <div className="grid grid-flow-row gap-1.5">
              <h2 className="font-semibold">{t("pages.watch.text6")}</h2>
              <div>{t("pages.watch.text7")}</div>
              <div>{t("pages.watch.text8.1")}</div>
              <div>{t("pages.watch.text8.2")}</div>
              <div>{t("pages.watch.text9")}</div>
              <div>{t("pages.watch.text10")}</div>
              <div>
                {t("pages.watch.text11")}
                <div>{t("pages.watch.text12")}</div>
              </div>
              <div>
                {t("pages.watch.text13")}
                <div>{t("pages.watch.text14")}</div>
              </div>
              <div>{t("pages.watch.text15")}</div>
              <div>
                {t("pages.watch.text16")}
                <div>{t("pages.watch.text17")}</div>
              </div>
              <div>{t("pages.watch.text18")}</div>
              <div>{t("pages.watch.text19")}</div>
              <div>{t("pages.watch.text20")}</div>
              <div>{t("pages.watch.text21")}</div>
              <div>{t("pages.watch.text22.1")}</div>
              <div>{t("pages.watch.text22.2")}</div>
              <div>{t("pages.watch.text23")}</div>
              <div>{t("pages.watch.text24")}</div>
              <div>{t("pages.watch.text25")}</div>
              <div>{t("pages.watch.text26")}</div>
              <div>{t("pages.watch.text27")}</div>
              <div>{t("pages.watch.text28")}</div>
              <div>{t("pages.watch.text29")}</div>
              <div>{t("pages.watch.text30")}</div>
              <div>{t("pages.watch.text31")}</div>
              <div>{t("pages.watch.text32")}</div>
              <div>{t("pages.watch.text33")}</div>
              <div>{t("pages.watch.text34")}</div>
              <div>{t("pages.watch.text35")}</div>
              <div>{t("pages.watch.text36")}</div>
              <div>{t("pages.watch.text37")}</div>
              <div>{t("pages.watch.text38")}</div>
              <div>{t("pages.watch.text39")}</div>
              <div>{t("pages.watch.text40.1")}</div>
              <div>{t("pages.watch.text40.2")}</div>
              <div>{t("pages.watch.text41")}</div>
              <div>{t("pages.watch.text42")}</div>
              <div>{t("pages.watch.text43")}</div>
              <div>{t("pages.watch.text44")}</div>
              <div>{t("pages.watch.text45")}</div>
              <div>{t("pages.watch.text46")}</div>
              <div>{t("pages.watch.text47.1")}</div>
              <div>{t("pages.watch.text47.2")}</div>
              <div>{t("pages.watch.text48")}</div>
              <div>{t("pages.watch.text49")}</div>
              <div>{t("pages.watch.text50")}</div>
              <div>{t("pages.watch.text51.1")}</div>
              <div>{t("pages.watch.text51.2")}</div>
              <div>{t("pages.watch.text52")}</div>
              <div>{t("pages.watch.text53.1")}</div>
              <div>{t("pages.watch.text53.2")}</div>
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">{t("pages.watch.text54")}</h2>
              {t("pages.watch.text55")}
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">{t("pages.watch.text56")}</h2>
              {t("pages.watch.text57")}
              <br />
              {t("pages.watch.text58")}
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default WatchFAQ;

import BackButton from "@src/components/nav/BackButton";
import { useTranslation } from "react-i18next";

const CopyRightContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <BackButton stepback desktopVisible />
      <div className="flex flex-col gap-3 text-xs text-gray-700">
        <h1 className="text-sm font-bold">{t("pages.copyrightContainer.text1")}</h1>
        <div>{t("pages.copyrightContainer.text2")} 26. März 2024</div>
        <h2 className="font-semibold">{t("pages.copyrightContainer.text3")}</h2>
        <div>{t("pages.copyrightContainer.text4")}</div>
        <div>{t("pages.copyrightContainer.text5")}</div>
        <div>{t("pages.copyrightContainer.text6")}</div>
        <h2 className="font-semibold">{t("pages.copyrightContainer.text7")}</h2>
        <h2 className="font-semibold">{t("pages.copyrightContainer.text8")}</h2>
        <div>{t("pages.copyrightContainer.text9")}</div>
        <ul className="list-inside list-decimal">
          <li>{t("pages.copyrightContainer.text10")}</li>
          <li>{t("pages.copyrightContainer.text11")}</li>
          <li>{t("pages.copyrightContainer.text12")}</li>
          <li>{t("pages.copyrightContainer.text13")}</li>
          <li>{t("pages.copyrightContainer.text14")}</li>
          <li>{t("pages.copyrightContainer.text15")}</li>
        </ul>
        <div>{t("pages.copyrightContainer.text16")}</div>
        <div>{t("pages.copyrightContainer.text17")}</div>
        <div>{t("pages.copyrightContainer.text18")}</div>
        <div className="flex flex-col gap-1">
          <div>André Fischer</div>
          <div>SCC Group</div>
          <div>August-Bebel-Straße 11/13</div>
          <div>09113 Chemnitz</div>
          <div>Telefon: +49 (0) 371 / 432 32 05 82</div>
          <div>E-Mail: datenschutz@tradefoox.com</div>
          <div>{t("pages.copyrightContainer.text19")}</div>
        </div>
        <h2 className="font-semibold">{t("pages.copyrightContainer.text20")}</h2>
        <div>{t("pages.copyrightContainer.text21")}</div>
        <ul className="list-inside list-decimal">
          <li>{t("pages.copyrightContainer.text22")}</li>
          <li>{t("pages.copyrightContainer.text23")}</li>
          <li>{t("pages.copyrightContainer.text24")}</li>
          <li>{t("pages.copyrightContainer.text25")}</li>
          <li>{t("pages.copyrightContainer.text26")}</li>
        </ul>
        <h2 className="font-semibold">{t("pages.copyrightContainer.text27")}</h2>
        <div>{t("pages.copyrightContainer.text28")}</div>
        <ul className="list-inside list-decimal">
          <li>{t("pages.copyrightContainer.text29")}</li>
          <li>{t("pages.copyrightContainer.text30")}</li>
          <li>{t("pages.copyrightContainer.text31")}</li>
        </ul>
        <div>{t("pages.copyrightContainer.text32")}</div>
      </div>
    </>
  );
};

export default CopyRightContainer;

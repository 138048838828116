import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import AuthWrapper from "@src/pages/auth/AuthWrapper";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ChangePasswordContainer = () => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const lastChangeDate = null;
  const { t } = useTranslation();
  const lastChangePlaceholder =
    "**************" +
    (lastChangeDate
      ? t("main.passwordChange.currentPasswordPlaceholder", {
          date: lastChangeDate,
        })
      : "");

  return (
    <AuthWrapper>
      <div className="mb-4 text-xl font-medium text-darkblue">
        {t("main.passwordChange.title1")}
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Input
          name="current password"
          label={t("main.passwordChange.currentPassword")}
          value={currentPassword}
          placeholder={lastChangePlaceholder}
          type="password"
          onChange={e => setCurrentPassword(e)}
        />
        <Input
          name="new password"
          label={t("main.passwordChange.newPassword")}
          value={newPassword}
          placeholder="**************"
          type="password"
          minLength={8}
          maxLength={32}
          pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$"
          onChange={e => setNewPassword(e)}
        />
        <div className="mx-auto mt-2 flex gap-x-4">
          <Button onClick={() => console.log("password changed")}>
            {t("main.passwordChange.changePassword")}
          </Button>
        </div>
      </form>
    </AuthWrapper>
  );
};

export default ChangePasswordContainer;

import Button from "@src/components/elements/input/Button";
import Checkbox from "@src/components/elements/input/Checkbox";
import Input from "@src/components/elements/input/Input";
import BackButton from "@src/components/nav/BackButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Unsubscripe = () => {
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  return (
    <>
      <BackButton stepback desktopVisible />
      <form className="text-justify text-xs">
        {t("pages.unsub.text1")}
        <Input
          type="email"
          name="email"
          placeholder="E-Mail*"
          value={email}
          required
          onChange={e => {
            setEmail(e);
          }}
        />
        <Checkbox name="agb" required checked={privacyCheck} onChange={e => setPrivacyCheck(e)}>
          {t("pages.createAccount.text17")}{" "}
          <NavLink to="/datenschutz" target="_blank">
            {t("pages.createAccount.text18")}
          </NavLink>{" "}
          {t("pages.createAccount.text19")}
        </Checkbox>
        <Button submit>{t("pages.unsub.text2")}</Button>
      </form>
    </>
  );
};

export default Unsubscripe;

import BackButton from "@src/components/nav/BackButton";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

type ArticleType = {
  title: string;
  text: ReactNode;
  link: string;
  youtubeLink: string;
};

const IndustrialHistory = () => {
  const { t } = useTranslation();
  const articles: Array<ArticleType> = [
    {
      title: "Tuchfabrik Gebr. Pfau in Crimmitschau",
      text: "Die Tuchfabrik Gebr. Pfau, ein außerordentliche Denkmal mit einem im Original erhaltenen Maschinenbestand aus über 100 Jahren Textilgeschichte, ermöglicht eine Zeitreise in den harten Arbeitsalltag in der Textilproduktion...",
      youtubeLink: "https://www.youtube-nocookie.com/embed/2ZY6VuxQPkM?controls=0",
      link: "/industriegeschichte/tuchfabrik_gebr_pfau_in_crimmitschau",
    },
    {
      title: "VEB Pentacon Dresden.",
      text: (
        <>
          Praktica - Da staunte die ganze Welt!
          <br />
          Praktica ist ursprünglich der Markenname für eine Baureihe von Spiegelreflexkameras des
          Herstellers Kamera-Werke Dresden-Niedersedlitz („KW“), der von Pentacon, Dresden-Striesen,
          übernommen wurde. Die in der DDR gefertigten Fotoapparate wurden auch ins Ausland
          exportiert...
        </>
      ),
      youtubeLink: "https://www.youtube-nocookie.com/embed/6iCdWG5v47I?controls=0",
      link: "/industriegeschichte/veb_pentacon_dresden",
    },
    {
      title: "Uranbergbau im Erzgebirge",
      text: "Es war eines der größten Geheimnisse der DDR - der Uranabbau im Erzgebirge. Hier wurde das Material für die Atombomben der Sowjetunion gewonnen. Die Folgen waren zerstörte Landschaften und kranke Menschen...",
      youtubeLink: "https://www.youtube-nocookie.com/embed/_aouDJLe_v0?controls=0",
      link: "/industriegeschichte/uranbergbau_im_erzgebirge",
    },
    {
      title: "VEB Flugzeugwerke Dresden",
      text: (
        <>
          1955 war das Startjahr für den Flugzeugbau in Dresden: Die Flugzeugwerke Dresden wurden
          gegründet. Brunolf Baade führte international anerkannte Experten aus Unternehmen der
          ehemaligen Reichsluftfahrtindustrie wie Junkers, Heinkel, Siebel oder Arado in Dresden
          zusammen.
          <br />
          Innerhalb von drei Jahren entstand bis 1958 die Vereinigung Volkseigener Betriebe (VVB)
          Flugzeugbau mit einer Gesamtbelegschaftsstärke von 25.000 Mitarbeitern, darunter allein
          8.000 im Kernbetrieb der VVB, dem Flugzeugwerk Dresden...
        </>
      ),
      youtubeLink: "https://www.youtube-nocookie.com/embed/_cURB_AEoR4?controls=0",
      link: "/industriegeschichte/veb_flugzeugwerke-dresden",
    },
    {
      title: "Braunkohle-Abbau im Leipziger Land und Espenhain",
      text: "Braunkohle-Abbau im Leipziger Umland zu DDR-Zeiten, der viele Menschen zur Umsiedlung zwang, und die damalige Landschaft massiv dezimierte. Viele kleine Dörfer verschwanden damals, bzw. wurden einfach weggebaggert, darunter auch der kleine Ort Eythra...",
      youtubeLink: "https://www.youtube-nocookie.com/embed/nnGCZOKGWIk?controls=0",
      link: "/industriegeschichte/braunkohleabbau_leipzig_espenhain",
    },
    {
      title: "Heimat in Zwickau - Antrieb aus Chemnitz",
      text: "Trabant heißt die ab 1958 in der DDR im VEB Automobilwerk Zwickau, später VEB Sachsenring Automobilwerke Zwickau, in Serie gebaute Pkw-Baureihe. Zur Zeit seiner Einführung galt er mit Frontantrieb und quer eingebautem Motor als moderner Kleinwagen und ermöglichte neben dem Wartburg die Massenmotorisierung in der DDR...",
      youtubeLink: "https://www.youtube-nocookie.com/embed/emoF0EFxjjA?controls=0",
      link: "/industriegeschichte/der_trabant",
    },
    {
      title: "Auto Union AG, Chemnitz",
      text: "Die Auto Union AG, Chemnitz war der erste deutsche staatliche Automobilkonzern. Er entstand aus der Fusion des Kleinwagen- und Motorradproduzenten Zschopauer Motorenwerke J. S. Rasmussen (DKW) mit seiner Tochtergesellschaft Audiwerke AG Zwickau, der Horchwerke AG (ebenfalls Zwickau) und dem Automobilwerk Siegmar der Wanderer-Werke in Schönau bei Chemnitz...",
      youtubeLink: "https://www.youtube-nocookie.com/embed/1D0gZjEvZcU?controls=0",
      link: "/industriegeschichte/auto_union_ag_chemnitz",
    },
    {
      title: "Eisenbahnmuseum Chemnitz",
      text: "Richard Hartmann, dessen Geburtstag sich 2009 zum 200. Mal jährte, gründete in Chemnitz seine Maschinenbauanstalt, die sich zur größten sächsischen Lokomotivfabrik entwickelte. Bereits 1848 wurde die “Glückauf” als erste in Chemnitz produzierte Dampflok getauft...",
      youtubeLink: "https://www.youtube-nocookie.com/embed/icp1QHXWv9o?controls=0",
      link: "/industriegeschichte/eisenbahnmuseum_chemnitz",
    },
    {
      title: "Entlang der Industrieroute - Straßenbahnmuseum Chemnitz",
      text: "Folgt man der Industrieroute entlang der Zwickauer Straße, so findet sich im Grundstück mit der Nummer 164 eine alte Gleisanlage, die an die Epoche der Chemnitzer Schmalspurstraßenbahn erinnert. In einer Wagenhalle aus dem Jahr 1908 beherbergt das Straßenbahnmuseum Chemnitz Relikte aus einer längst vergangenen Zeit...",
      youtubeLink: "https://www.youtube-nocookie.com/embed/rhEqhA3Xm2A?controls=0",
      link: "/industriegeschichte/strassenbahnmuseum_chemnitz",
    },
    {
      title: "Museum für sächsische Fahrzeuge Chemnitz",
      text: "In den “Stern-Garagen”, einer der ältesten erhaltenen deutschen Hochgaragen, bietet sich der passende historische Rahmen für rund 200 Exponate von mehr als 70 Herstellern. Auf 1.000 Quadratmetern präsentieren sich dem Besucher über 150 Automobile, Motorräder und Fahrräder...",
      youtubeLink: "https://www.youtube-nocookie.com/embed/TzxR80gcPqE?controls=0",
      link: "/industriegeschichte/museum_für_sächsische_fahrzeuge_chemnitz",
    },
    {
      title: "Industriemuseum Chemnitz",
      text: "Der Maschinenbau boomt: Fabriken für Maschinen und Maschinenbau entstehen, in denen Textil-, Dampf-, Werkzeug- und andere Maschinen „Made in Sachsen“ gefertigt werden. Für die Gäste wird erlebbar, wie die Menschen im Laufe der Zeit Maschinen perfektionierten und wie der Maschinenboom ihren Alltag revolutionierte...",
      youtubeLink: "https://www.youtube-nocookie.com/embed/9vD4Hv4FiAI?controls=0",
      link: "/industriegeschichte/industriemuseum_chemnitz",
    },
    {
      title: "09.06.2019 Fritz Heckert - Das zeitweise zweitgrößte Baugebiet der DDR",
      text: "Der Name „Fritz Heckert“ steht auch heute noch für ein gigantisches Neubauprojekt in der ehemaligen DDR. Am 5. Oktober 1974 wurde der Grundstein für das zeitweise zweitgrößte Baugebiet der DDR gelegt, in dem noch 1990 rund 90.000 Chemnitzer wohnten...",
      youtubeLink: "https://www.youtube-nocookie.com/embed/YzkLcfKb5Uk?controls=0",
      link: "/industriegeschichte/fritz_heckert_chemnitz",
    },
    {
      title: "Die Entstehungsgeschichte von BMW",
      text: "Sicherlich sah Karl Rapp zur Gründung seiner Motorenwerke GmbH im Jahr 1913 nicht, welchen Stein er damit ins Rollen bringen würde. Die Rapp Motorenwerke GmbH, welche von Philipp Dörhöfer (Berlin) und Schneeweis (Chemnitz) sind, waren die Vorgänger der heutigen BMW Fahrzeuge. Schneeweis konnte jahrelange...",
      youtubeLink: "https://www.youtube-nocookie.com/embed/tybxjI0Vx_Q?controls=0",
      link: "/industriegeschichte/bmw",
    },
    {
      title: "Chemnitz nach dem zweiten Weltkrieg",
      text: "Bis zum zweiten Weltkrieg galt Chemnitz als der bekannteste Standort des deutschen Werkzeugmaschinenbaus. Die Verbindung von Maschinenbau, Produktion von Konsumgütern und spezialisierten Zulieferbetrieben wurde bereits damals schon erkannt und ist auch noch bis heute für die komplette Region prägend...",
      youtubeLink: "https://www.youtube-nocookie.com/embed/i30dySdThb0?controls=0",
      link: "/industriegeschichte/chemnitz_nach_dem_zweiten_weltkrieg",
    },
    {
      title: "Lutherkirche in Chemnitz",
      text: "Die Geschichte der Lutherkirche beginnt früher als das Bauwerk selbst - nämlich in der Johannisparochie. Durch die industrielle Entwicklung der damaligen Zeit wuchs die Stadt Chemnitz rasant und dehnte sich nach Süden aus. Um alle Gläubigen versorgen zu können, wurde das Gebiet der Johannisgemeinde bald zu klein...",
      youtubeLink: "https://www.youtube-nocookie.com/embed/8YSrob9Nq7k?controls=0",
      link: "/industriegeschichte/lutherkirche_chemnitz",
    },
    {
      title: "Flughafenbetrieb in Chemnitz",
      text: "Chemnitz hatte vor vielen Jahren einen Flughafen mit regem Verkehrsbetrieb. Lasst uns einen Blick in die Vergangenheit werfen: Im Jahr 1896 wurde der „Chemnitzer Verein für Luftfahrt“ gegründet – übrigens der erste seiner Art. Nur wenige Jahre später – im Jahr 1911 – war der erste Flugbetrieb auf dem alten Flughafen an der Zschopauer Straße zu verzeichnen...",
      youtubeLink: "https://www.youtube-nocookie.com/embed/5CYiTMAwiBY?controls=0",
      link: "/industriegeschichte/flughafen_chemnitz",
    },
    {
      title: "Roter Turm Chemnitz",
      text: "Den Roten Turm kann man als DAS Wahrzeichen von Chemnitz betrachten, denn es ist das älteste erhaltene Bauwerk und einer der wenigen Zeitzeugen der Stadt. Genau genommen ist das Gebäude älter als die Stadt selbst. Als in den Jahren nach 1100 die Bauern zur Landbesiedlung Richtung Osten zogen, entstanden in um und Chemnitz viele Siedlungen, welche überwacht, kontrolliert und...",
      youtubeLink: "https://www.youtube-nocookie.com/embed/Sg0vGpgDAkY?controls=0",
      link: "/industriegeschichte/roter_turm_chemnitz",
    },
    {
      title: "Chemnitz - von der Industriestadt zur Stadt der Moderne",
      text: "Warum wir uns in Chemnitz niedergelassen haben? Ganz einfach: Die Stadt Chemnitz ist eine kreisfreie Stadt im Südwesten des Freistaates Sachsen und nach Leipzig und Dresden die drittgrößte Stadt im Bundesland. Der Namensgeber ist der gleichnamige Fluss Chemnitz (aus dem sorbischen: Kamenica = Steinbach). Chemnitz greift auf mehr als 200 Jahre alte Industriegeschichte zurück. Bereits seit dem 16. Jahrhundert wird die Stadt mit dem Bergbau und der Textilherstellung verbunden...",
      youtubeLink: "https://www.youtube-nocookie.com/embed/kFIp_78OqU4?controls=0",
      link: "/industriegeschichte/von_der_industriestadt_zur_stadt_der_moderne",
    },
  ];

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="grid grid-flow-row gap-6 text-justify text-xs text-gray-700">
        <section className="relative">
          <img
            src="/images/info/industriegeschichte_sachsen.jpg"
            className="h-[300px] w-full object-cover"
          />

          <div className="absolute left-6 top-1/2 flex -translate-y-1/2 flex-col font-semibold italic">
            <div className="w-fit whitespace-nowrap bg-white bg-opacity-80 px-1 text-left text-smbase font-bold text-darkcyan">
              {t("pages.industrialHistory.text1")}
            </div>
            <div className="ml-4 w-fit bg-darkblue bg-opacity-80 px-1 text-white">
              {t("pages.industrialHistory.text2")}
            </div>
          </div>
        </section>

        {articles.map((article: ArticleType, index) => {
          return (
            <article className="flex flex-col gap-1 text-xs" key={index}>
              <h2 className="text-smbase font-bold max-xl:px-4">{article.title}</h2>
              <iframe
                src={article.youtubeLink}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                width="100%"
                height="300px"
              />
              <p className="text-sm max-xl:px-4">
                {article.text}{" "}
                <NavLink to={article.link} className="font-semibold">
                  [weiterlesen]
                </NavLink>
              </p>
            </article>
          );
        })}
      </div>
    </>
  );
};

export default IndustrialHistory;

import me from "@src/api/auth/me";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import AuthWrapper from "@src/pages/auth/AuthWrapper";
import { useAppSelector } from "@src/state/hooks";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ChangeEmailContainer = () => {
  const [_currentEmail, setCurrentEmail] = useState<string>("");
  const [newEmail, setNewEmail] = useState<string>("");
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();

  const meResult = useQuery({
    queryKey: ["me"],
    queryFn: () => me(),
    enabled: !!user,
  });

  if (!meResult.isSuccess) return <></>;

  let maskedEmail = meResult.data.data.email ?? "";

  if (maskedEmail.length > 5) {
    maskedEmail = maskedEmail.slice(0, 5) + "X".repeat(maskedEmail.length - 5);
  }

  return (
    <AuthWrapper>
      <div className="mb-4 text-xl font-medium text-darkblue">
        {t("main.panel.profileWidgets.changeEmail")}{" "}
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Input
          name="current email"
          label={t("main.panel.profileWidgets.currentEmail")}
          value={maskedEmail}
          placeholder={maskedEmail}
          type="text"
          onChange={e => setCurrentEmail(e)}
          disabled
        />
        <Input
          name="new email"
          label={t("main.panel.profileWidgets.newEmail")}
          value={newEmail}
          placeholder="**************"
          type="text"
          onChange={e => setNewEmail(e)}
        />
        <div className="mx-auto mt-2 flex gap-x-4">
          <Button onClick={() => console.log("email changed")}>
            {t("main.panel.profileWidgets.changeEmail")}
          </Button>
        </div>
      </form>
    </AuthWrapper>
  );
};

export default ChangeEmailContainer;

import BackButton from "@src/components/nav/BackButton";
import { useTranslation } from "react-i18next";

const MemberFAQ = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="grid grid-flow-row gap-6 text-gray-700">
        <section className="relative">
          <img
            src="/images/info/projekt-auftragsvergabe-header-5.jpg"
            className="h-[300px] w-full object-cover"
          />
          <div className="absolute left-6 top-1/2 flex -translate-y-1/2 flex-col font-semibold italic">
            <div className="w-fit bg-white bg-opacity-80 px-1 text-2xl text-darkcyan">
              {t("pages.memberFAQ.members")}
            </div>
            <div className="ml-4 w-fit bg-darkblue bg-opacity-80 px-1 text-white">
              {t("pages.companyFAQ.tradefoox")}
            </div>
          </div>
        </section>

        <article className="pb-4 text-sm max-lg:px-4">
          <h1 className="mb-4 text-base font-semibold">{t("pages.memberFAQ.info")}</h1>
          <div className="grid grid-flow-row gap-3">
            <div className="grid grid-flow-row gap-3">
              <div>
                <h2 className="font-semibold">{t("pages.memberFAQ.text1")}</h2>
                {t("pages.memberFAQ.text2")}
              </div>
              <div>
                <h2 className="font-semibold">{t("pages.memberFAQ.text3")}</h2>
                {t("pages.memberFAQ.text4")}
              </div>
              <div>
                <h2 className="font-semibold">{t("pages.memberFAQ.text5")}</h2>
                {t("pages.memberFAQ.text6")}
              </div>
              <div>
                <h2 className="font-semibold">{t("pages.memberFAQ.text7")}</h2>
                {t("pages.memberFAQ.text8")}
              </div>
              <div>
                <h2 className="font-semibold">{t("pages.memberFAQ.text9")}</h2>
                {t("pages.memberFAQ.text10")}
              </div>
              <div>
                <h2 className="font-semibold">{t("pages.memberFAQ.text11")}</h2>
                {t("pages.memberFAQ.text12")}
              </div>
              <div>
                <h2 className="font-semibold">{t("pages.memberFAQ.text13")}</h2>
                {t("pages.memberFAQ.text14")}
              </div>
              <div>
                <h2 className="font-semibold">{t("pages.memberFAQ.text15")}</h2>
                {t("pages.memberFAQ.text16")}
              </div>
              <div>
                <h2 className="font-semibold">{t("pages.memberFAQ.text17")}</h2>
                {t("pages.memberFAQ.text18")}
              </div>
              <div>
                <h2 className="font-semibold">{t("pages.memberFAQ.text19")}</h2>
                {t("pages.memberFAQ.text20")}
              </div>
              <div>
                <h2 className="font-semibold">{t("pages.memberFAQ.text21")}</h2>
                {t("pages.memberFAQ.text22")}
              </div>
              <div>
                <h2 className="font-semibold">{t("pages.companyFAQ.text31")}</h2>
                {t("pages.companyFAQ.text32")}
              </div>
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default MemberFAQ;

import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import AuthWrapper from "@src/pages/auth/AuthWrapper";
import { useAppSelector } from "@src/state/hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ChangeUsernameContainer = () => {
  const [currentUsername, setCurrentUsername] = useState<string>("");
  const [newUsername, setNewUsername] = useState<string>("");
  const { t } = useTranslation();
  const { user } = useAppSelector(state => state.user);

  if (!user) {
    return <></>;
  }

  return (
    <AuthWrapper>
      <div className="mb-4 text-xl font-medium text-darkblue">
        {t("main.usernameChange.title1")}
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Input
          name="current username"
          label={t("main.usernameChange.currentUsername")}
          value={currentUsername}
          placeholder={user.username}
          type="text"
          onChange={e => setCurrentUsername(e)}
          disabled
        />
        <Input
          name="new username"
          label={t("main.usernameChange.newUsername")}
          value={newUsername}
          placeholder="**************"
          type="text"
          onChange={e => setNewUsername(e)}
        />

        <div>{t("main.usernameChange.nameChangeLimit", { count: 3 })}</div>
        <div className="mx-auto mt-2 flex gap-x-4">
          <Button onClick={() => console.log("username changed")}>
            {t("main.usernameChange.changeUsername")}
          </Button>
        </div>
      </form>
    </AuthWrapper>
  );
};

export default ChangeUsernameContainer;
